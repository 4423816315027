.checklist:hover {
  background-color: #deebff;
}

.search-popup-menu-box > div:first-of-type {
  max-width: 800px !important;
  min-width: 400px !important;
  max-height: 500px !important;
  overflow-y: scroll;
}
