.rdg-cell {
  border-inline-end: 1px solid var(--rdg-border-color) !important;
  border-block-end: 1px solid var(--rdg-border-color) !important;
}

.rdg-summary-row .rdg-cell {
  border-block-start: 1px solid var(--rdg-summary-border-color) !important;
}

.rdg-header-row .rdg-cell {
  border-block-start: 1px solid var(--rdg-border-color) !important;
}
